import "./App.css";
import React, { useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Loading from "./Loading";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

function ResultRow({ result, handleAddToSelection }) {
  return (
    <tr>
      <td>
        <UnixTimeToDate unixTime={result.create_time} />
      </td>
      <td>
        <a
          href={`https://lihkg.com/thread/${result.thread_id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none text-primary"
        >
          {result.post_name}
        </a>
      </td>
      <td className="text-center">
        <button
          className="btn btn-outline-success btn-sm"
          onClick={() => handleAddToSelection(result)}
        >
          ✅ Select
        </button>
        <span className="ms-2 text-muted">({result.page_number})</span>
      </td>
    </tr>
  );
}

function UnixTimeToDate({ unixTime }) {
  const timestampInMilliseconds = unixTime * 1000;
  const date = new Date(timestampInMilliseconds);
  const formattedDate = format(date, "dd-MM-yyyy");
  return <span>{formattedDate}</span>;
}

function PageSelect({ currentPage, totalPages, handlePageChange }) {
  return (
    <div className="d-flex justify-content-center align-items-center my-3">
      <label htmlFor="pageSelect" className="me-2">
        Select Page:
      </label>
      <select
        id="pageSelect"
        className="form-select form-select-sm w-auto"
        value={currentPage}
        onChange={handlePageChange}
      >
        {Array.from({ length: totalPages }, (_, index) => (
          <option key={index + 1} value={index + 1}>
            Page {index + 1}
          </option>
        ))}
      </select>
    </div>
  );
}

function App() {
  const { t, i18n } = useTranslation();
  const [keywords, setKeywords] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedThreadIds, setSelectedThreadIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [sortBy, setSortBy] = useState("page_number_desc");
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSearchResults([]);
    setCurrentPage(1);

    axios
      .get("https://www.scrapeion.com/api/search-posts", {
        params: { keywords },
      })
      .then((response) => {
        setSearchResults(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  };

  const handleButtonClick = () => {
    setIsLoading(true);
    axios({
      method: "post",
      url: "https://www.scrapeion.com/api/scrape-comments",
      data: { post_ids: selectedThreadIds.map((value) => value.id) },
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        const contentDisposition = response.headers.get("content-disposition");
        const filename = contentDisposition
          ? contentDisposition.split("filename=")[1]
          : "comments.csv";

        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  };

  const handlePageChange = (event) =>
    setCurrentPage(Number(event.target.value));
  const handleAddToSelection = (result) => {
    if (!selectedThreadIds.some((item) => item.id === result.id)) {
      setSelectedThreadIds([...selectedThreadIds, result]);
    }
  };
  const handleRemoveFromSelection = (resultToRemove) =>
    setSelectedThreadIds(
      selectedThreadIds.filter((result) => result !== resultToRemove)
    );
  const handleSortBy = (sortOrder) => {
    const sortedResults = [...searchResults];
    if (sortOrder === "page_number_desc") {
      sortedResults.sort((a, b) => b.page_number - a.page_number);
    } else if (sortOrder === "create_time_desc") {
      sortedResults.sort((a, b) => b.create_time - a.create_time);
    } else if (sortOrder === "create_time_asc") {
      sortedResults.sort((a, b) => a.create_time - b.create_time);
    }
    setSearchResults(sortedResults);
    setSortBy(sortOrder);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentResults = searchResults.slice(startIndex, endIndex);

  return (
    <div className="App">
      <Helmet>{/* Include external scripts */}</Helmet>
      <div className="container mt-5">
        {/* Language Switcher */}
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-outline-primary btn-sm me-2"
            onClick={() => changeLanguage("en")}
          >
            English
          </button>
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={() => changeLanguage("zh")}
          >
            中文
          </button>
        </div>

        {/* Title and Guidelines */}
        <h1 className="text-center">{t("title")}</h1>
        <p className="text-muted text-center">
          Quickly scrape posts and comments from the Lihkg forum.
        </p>
        <p className="text-center text-secondary">
          <strong>
            {t("guidelines.step1", { search: t("searchButton") })}
          </strong>
          <br />
          {t("guidelines.step2", { select: "✅ Select" })}
          <br />
          {t("guidelines.step3", { scrape: t("scrapeButton") })}
        </p>

        {/* Search Form */}
        <form onSubmit={handleSubmit} className="mb-4">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={keywords}
              onChange={(event) => setKeywords(event.target.value)}
              placeholder={t("searchPlaceholder")}
            />
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isLoading}
            >
              {isLoading ? t("downloading") : t("searchButton")}
            </button>
          </div>
        </form>

        {/* Search Results and Selected Posts */}
        <div className="row">
          {/* Search Results */}
          <div className="col-lg-8">
            {searchResults.length > 0 && (
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <p className="mb-0">
                  {t("foundResults", { count: searchResults.length })}
                </p>
                <select
                  className="form-select form-select-sm w-auto"
                  value={sortBy}
                  onChange={(event) => handleSortBy(event.target.value)}
                >
                  <option value="page_number_desc">{t("mostCommented")}</option>
                  <option value="create_time_desc">{t("newestFirst")}</option>
                  <option value="create_time_asc">{t("oldestFirst")}</option>
                </select>
              </div>
            )}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>{t("createTime")}</th>
                  <th>{t("postName")}</th>
                  <th className="text-center">{t("actions")}</th>
                </tr>
              </thead>
              <tbody>
                {currentResults.length > 0 ? (
                  currentResults.map((result) => (
                    <ResultRow
                      key={uuidv4()}
                      result={result}
                      handleAddToSelection={handleAddToSelection}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center">
                      {t("noResults")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {searchResults.length > itemsPerPage && (
              <PageSelect
                currentPage={currentPage}
                totalPages={Math.ceil(searchResults.length / itemsPerPage)}
                handlePageChange={handlePageChange}
              />
            )}
          </div>

          {/* Selected Posts */}
          <div className="col-lg-4">
            <h5>{t("selectedPosts")}</h5>
            {selectedThreadIds.length === 0 ? (
              <p className="text-muted">{t("noPosts")}</p>
            ) : (
              <ul className="list-group">
                {selectedThreadIds.map((result) => (
                  <li
                    key={result.id}
                    className="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <a
                      href={`https://lihkg.com/thread/${result.thread_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none"
                    >
                      {result.post_name}
                    </a>
                    <button
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => handleRemoveFromSelection(result)}
                    >
                      ❌
                    </button>
                  </li>
                ))}
              </ul>
            )}
            {selectedThreadIds.length > 0 && (
              <button
                className="btn btn-success w-100 mt-3"
                onClick={handleButtonClick}
              >
                {isLoading ? t("downloading") : t("scrapeButton")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
