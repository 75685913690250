import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./Homepage";
import AdsTxt from "./Adstxt";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { I18nextProvider } from "react-i18next";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    {/* Use Switch to render the first matching route */}
    <Routes>
      {/* Define routes using Route component */}
      <Route path="/" element={<Homepage />} />
      <Route path="/ads.txt" element={<AdsTxt />} />
    </Routes>
  </Router>
);

reportWebVitals();
