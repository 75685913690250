import React, { useEffect, useState } from "react";

function AdsTxt() {
  const [adsTxtContent, setAdsTxtContent] = useState("");

  useEffect(() => {
    // Fetch the ads.txt file content when the component mounts
    fetch("/ads.txt")
      .then((response) => response.text())
      .then((text) => setAdsTxtContent(text))
      .catch((error) => console.error("Error fetching ads.txt:", error));
  }, []);

  return <pre>{adsTxtContent}</pre>;
}

export default AdsTxt;
