import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";

i18n
  .use(HttpBackend) // Load translation files
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Pass i18n instance to react-i18next
  .init({
    fallbackLng: "en", // Default language
    debug: true,
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    resources: {
      en: {
        translation: {
          title: "Lihkg Scraper",
          guidelines: {
            step1:
              "Step 1: Enter the post keywords and click {{search}} to get the related posts.",
            step2:
              "Step 2: Click {{select}} to select the exact post you would like to scrape.",
            step3:
              "Click {{scrape}} to generate a CSV file, which will be available for download.",
          },
          searchPlaceholder: "Enter keywords...",
          searchButton: "Search",
          downloading: "Downloading...",
          scrapeButton: "Scrape Selected Posts",
          noPosts: "No posts selected.",
          foundResults: "Found {{count}} results",
          newestFirst: "Newest First",
          oldestFirst: "Oldest First",
          mostCommented: "Most Commented",
          noResults: "No results found.",
          selectedPosts: "Selected Posts",
        },
      },
      zh: {
        translation: {
          title: "連登討論區 留言 抓取器/爬蟲",
          guidelines: {
            step1: "步驟 1：輸入帖子關鍵字並點擊 {{search}} 獲取相關帖子。",
            step2: "步驟 2：點擊 {{select}} 選擇要抓取的帖子。",
            step3: "點擊 {{scrape}} 生成 CSV 文件，該文件將可供下載。",
          },
          searchPlaceholder: "輸入關鍵字...",
          searchButton: "搜尋",
          downloading: "下載中...",
          scrapeButton: "抓取選定帖子",
          noPosts: "沒有選中的帖子。",
          foundResults: "找到 {{count}} 條結果",
          newestFirst: "最新優先",
          oldestFirst: "最舊優先",
          mostCommented: "最多評論",
          noResults: "沒有找到結果。",
          selectedPosts: "選定的帖子",
        },
      },
    },
  });

export default i18n;
